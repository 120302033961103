<template>
  <section class="section is-paddingless">
    <Navbar />
    <br><br>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-9">
          <h2 class="my-5 title has-text-centered">Disclaimer</h2>
          <div data-custom-class="subtitle">
            <strong>Last updated&nbsp;</strong>
            <strong>November 01, 2018</strong>
          </div>
          <br>
          <div data-custom-class="heading_1">
            <strong>WEBSITE DISCLAIMER</strong>
          </div>
          <br>
          <div data-custom-class="body_text">
            The information provided by Morpher Labs GmbH (“we,” “us” or “our”)
            on https://www.morpher.com (the “Site”) is for general informational
            purposes only. All information on the Site is provided in good
            faith, however we make no representation or warranty of any kind,
            express or implied, regarding the accuracy, adequacy, validity,
            reliability, availability or completeness of any information on the
            Site. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR
            ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF
            THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR
            USE OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE IS
            SOLELY AT YOUR OWN RISK.
          </div>
          <div>&nbsp;</div>
          <div data-custom-class="heading_1">
            <strong>EXTERNAL LINKS DISCLAIMER</strong>
          </div>
          <br>
          <div data-custom-class="body_text">
            The&nbsp;Site may contain (or you may be sent through the Site)
            links&nbsp;to other websites or content belonging to or originating
            from third parties or links to websites and features in banners or
            other advertising. Such external links are not investigated,
            monitored, or checked for accuracy, adequacy, validity, reliability,
            availability or completeness by us. WE DO NOT WARRANT, ENDORSE,
            GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY
            OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH
            THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER
            ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE
            FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS
            OF PRODUCTS OR SERVICES.
          </div>
          <div>&nbsp;</div>
          <div data-custom-class="heading_1">
            <strong>PROFESSIONAL DISCLAIMER</strong>
          </div>
          <br>
          <div data-custom-class="body_text">
            The Site cannot and does not contain financial advice. The financial
            information is provided for general informational and educational
            purposes only and is not a substitute for professional advice.
            Accordingly, before taking any actions based upon such information,
            we encourage you to consult with the appropriate professionals. We
            do not provide any kind of financial advice. THE USE OR RELIANCE OF
            ANY INFORMATION CONTAINED ON THIS SITE IS SOLELY AT YOUR OWN RISK.
          </div>
          <div>&nbsp;</div>
        </div>
      </div>
    </div>
    <br>
    <Footer :disclaimerContent="0" />
  </section>
</template>

<script>
import Navbar from "@/components/partials/Navbar.vue";
import Footer from "@/components/partials/Footer";

export default {
	name: "Disclaimer",
	components: {
		Navbar,
		Footer,
	},
	mounted() {
		// $crisp.push(["do", "chat:hide"]);
		document.dispatchEvent(new Event("x-app-rendered"));
	},
	head() {
		return {
			title: {
				inner: this.$t('meta.DISCLAMER'),
			},
			meta: [
				{
					name: "description",
					content: this.$t('meta.DISCLAMER_DESC'),
					id: "desc",
				},
				{ property: "og:title", content: this.$t('meta.DISCLAMER'), id: 'og-title' },
				{
					property: "og:description",
					content: this.$t('meta.DISCLAMER_DESC'),
          id: 'og-description'
				},
				{ property: "og:image", content: "", id: 'og-image' },
				{ property: "twitter:card", content: "", id: 'twitter-card' },
				{ property: "twitter:image", content: "", id: 'twitter-image' },
			],
			link: [
				{
					rel: "canonical",
					href: "https://www.morpher.com" + this.$route.path,
					id: "canonical",
				},
			],
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
